/* eslint-disable camelcase */

import moment from "moment";

export const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const MONTH_NAMES_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE_IN_MB = 5;
export const PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE = PRODUCT_FEEDBACK_FORM_MAX_FILE_SIZE_IN_MB * 1024 * 1024;
export const PRODUCT_FEEDBACK_FORM_FEEDBACK_DROPDOWN_VALUES = [
  { value: "Bug", label: "Bug" },
  { value: "Task", label: "Feature Improvement" },
];

export const TIMEOFF_REQUESTS = [
  {
    title: "Pending",
    link: "",
  },
  {
    title: "Scheduled",
    link: "",
  },
  {
    title: "Taken",
    link: "",
  },
  {
    title: "Rejected",
    link: "",
  },
  {
    title: "Cancelled",
    link: "",
  },
];

export const Employee_TIMEOFF_REQUESTS = [
  {
    title: "All",
    link: "",
  },
  {
    title: "Scheduled",
    link: "",
  },

  {
    title: "Rejected",
    link: "",
  },

  {
    title: "Pending",
    link: "",
  },

  {
    title: "Taken",
    link: "",
  },
  {
    title: "Cancelled",
    link: "",
  },
];
export const ADMIN_WORKFORCE_TABS = [
  {
    title: "Overview",
    link: "",
  },
  {
    title: "Contact",
    link: "",
  },
  {
    title: "Salary Details",
    link: "",
  },
  {
    title: "Education",
    link: "",
  },
  {
    title: "Experience",
    link: "",
  },
  {
    title: "Documents",
    link: "",
  },
  {
    title: "Notes",
    link: "",
  },
];

export const TIMEOFF_CUSTOMER = [
  {
    title: "Pending",
    link: "",
  },
  {
    title: "Scheduled",
    link: "",
  },
  {
    title: "Rejected",
    link: "",
  },
  {
    title: "Cancelled",
    link: "",
  },
  {
    title: "Taken",
    link: "",
  },
];

export const WORKFORCE_PROFILE_TABS = [
  {
    key: "overview",
    title: "Overview",
    link: "",
  },
  {
    key: "education",
    title: "Education",
    link: "",
  },
  {
    key: "experience",
    title: "Experience",
    link: "",
  },
  {
    key: "documents",
    title: "Documents",
    link: "",
  },
  {
    key: "payroll-history",
    title: "Payroll History",
    link: "",
  },
  {
    key: "time-off",
    title: "Time Off",
    link: "",
  },
];

export const EMPLOYEE_PROFILE_TABS = [
  {
    key: "about",
    title: "About",
    link: "",
  },
  {
    key: "education",
    title: "Education",
    link: "",
  },
  {
    key: "experience",
    title: "Experience",
    link: "",
  },
  {
    key: "documents",
    title: "Documents",
    link: "",
  },
];

export const getStartDateAndEndDate = (selectedKey) => {
  const today = moment().startOf("day");
  let startDate;
  let endDate;

  switch (selectedKey) {
    case "thisWeek":
      startDate = moment().startOf("week");
      endDate = moment().endOf("week");
      break;
    case "thisYear":
      startDate = moment().startOf("year");
      endDate = moment().endOf("year");
      break;
    case "thisMonth":
      startDate = moment().startOf("month");
      endDate = moment().endOf("month");
      break;
    case "last6Months":
      startDate = moment().subtract(6, "months").startOf("month");
      endDate = today;
      break;
    default:
      // Handle default case or provide a default range
      break;
  }

  return {
    startDate: startDate.format("YYYY-MM-DD"),
    endDate: endDate.format("YYYY-MM-DD"),
  };
};

export const USER_TYPES = {
  EMPLOYEE: "employee",
  CUSTOMER: "customer",
  EDGE: "edge",
};

export const LEAVE_STATUSES = {
  PENDING: "Pending",
  SCHEDULED: "Scheduled",
};

export const BG_COLORS = [
  { path: "/main/payment", color: "#FAFAFF" },
  { path: "/main/timeoff/admin/Utilisation-report", color: "#FEFEFE" },
  { path: "/main/timeoff", color: "#FEFEFE" },
  { path: "/main/timeoff/request", color: "#FEFEFE" },
  { path: "/main/settings", color: "#FFFFFF" },
  { path: "/main/profile", color: "#FFFFFF" },
];

export const GENDERS = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const MARITAL_STATUS = [
  {
    value: "Single",
    label: "Single",
  },
  {
    value: "Married",
    label: "Married",
  },
  {
    value: "Divorced",
    label: "Divorced",
  },
  {
    value: "Widowed",
    label: "Widowed",
  },
];

export const COUNTRY_CODES = [
  { code: "+92", country: "Pakistan", iso2: "PK", preferred: true },
  { code: "+51", country: "Peru", iso2: "PE", preferred: true },
  { code: "+1", country: "United States", iso2: "US", preferred: true },
  { code: "+93", country: "Afghanistan", iso2: "AF" },
  { code: "+355", country: "Albania", iso2: "AL" },
  { code: "+213", country: "Algeria", iso2: "DZ" },
  { code: "+376", country: "Andorra", iso2: "AD" },
  { code: "+244", country: "Angola", iso2: "AO" },
  { code: "+672", country: "Antarctica", iso2: "AQ" },
  { code: "+54", country: "Argentina", iso2: "AR" },
  { code: "+374", country: "Armenia", iso2: "AM" },
  { code: "+61", country: "Australia", iso2: "AU" },
  { code: "+43", country: "Austria", iso2: "AT" },
  { code: "+994", country: "Azerbaijan", iso2: "AZ" },
  { code: "+973", country: "Bahrain", iso2: "BH" },
  { code: "+880", country: "Bangladesh", iso2: "BD" },
  { code: "+375", country: "Belarus", iso2: "BY" },
  { code: "+32", country: "Belgium", iso2: "BE" },
  { code: "+501", country: "Belize", iso2: "BZ" },
  { code: "+229", country: "Benin", iso2: "BJ" },
  { code: "+975", country: "Bhutan", iso2: "BT" },
  { code: "+591", country: "Bolivia", iso2: "BO" },
  { code: "+387", country: "Bosnia and Herzegovina", iso2: "BA" },
  { code: "+267", country: "Botswana", iso2: "BW" },
  { code: "+55", country: "Brazil", iso2: "BR" },
  { code: "+673", country: "Brunei", iso2: "BN" },
  { code: "+359", country: "Bulgaria", iso2: "BG" },
  { code: "+226", country: "Burkina Faso", iso2: "BF" },
  { code: "+257", country: "Burundi", iso2: "BI" },
  { code: "+855", country: "Cambodia", iso2: "KH" },
  { code: "+237", country: "Cameroon", iso2: "CM" },
  { code: "+1", country: "Canada", iso2: "CA" },
  { code: "+238", country: "Cape Verde", iso2: "CV" },
  { code: "+236", country: "Central African Republic", iso2: "CF" },
  { code: "+235", country: "Chad", iso2: "TD" },
  { code: "+56", country: "Chile", iso2: "CL" },
  { code: "+86", country: "China", iso2: "CN" },
  { code: "+57", country: "Colombia", iso2: "CO" },
  { code: "+269", country: "Comoros", iso2: "KM" },
  { code: "+242", country: "Congo", iso2: "CG" },
  { code: "+506", country: "Costa Rica", iso2: "CR" },
  { code: "+385", country: "Croatia", iso2: "HR" },
  { code: "+53", country: "Cuba", iso2: "CU" },
  { code: "+357", country: "Cyprus", iso2: "CY" },
  { code: "+420", country: "Czech Republic", iso2: "CZ" },
  { code: "+45", country: "Denmark", iso2: "DK" },
  { code: "+253", country: "Djibouti", iso2: "DJ" },
  { code: "+1-767", country: "Dominica", iso2: "DM" },
  { code: "+1-809", country: "Dominican Republic", iso2: "DO" },
  { code: "+670", country: "East Timor", iso2: "TL" },
  { code: "+593", country: "Ecuador", iso2: "EC" },
  { code: "+20", country: "Egypt", iso2: "EG" },
  { code: "+503", country: "El Salvador", iso2: "SV" },
  { code: "+240", country: "Equatorial Guinea", iso2: "GQ" },
  { code: "+291", country: "Eritrea", iso2: "ER" },
  { code: "+372", country: "Estonia", iso2: "EE" },
  { code: "+251", country: "Ethiopia", iso2: "ET" },
  { code: "+679", country: "Fiji", iso2: "FJ" },
  { code: "+358", country: "Finland", iso2: "FI" },
  { code: "+33", country: "France", iso2: "FR" },
  { code: "+241", country: "Gabon", iso2: "GA" },
  { code: "+220", country: "Gambia", iso2: "GM" },
  { code: "+995", country: "Georgia", iso2: "GE" },
  { code: "+49", country: "Germany", iso2: "DE" },
  { code: "+233", country: "Ghana", iso2: "GH" },
  { code: "+30", country: "Greece", iso2: "GR" },
  { code: "+299", country: "Greenland", iso2: "GL" },
  { code: "+1-473", country: "Grenada", iso2: "GD" },
  { code: "+502", country: "Guatemala", iso2: "GT" },
  { code: "+224", country: "Guinea", iso2: "GN" },
  { code: "+245", country: "Guinea-Bissau", iso2: "GW" },
  { code: "+592", country: "Guyana", iso2: "GY" },
  { code: "+509", country: "Haiti", iso2: "HT" },
  { code: "+504", country: "Honduras", iso2: "HN" },
  { code: "+852", country: "Hong Kong", iso2: "HK" },
  { code: "+36", country: "Hungary", iso2: "HU" },
  { code: "+354", country: "Iceland", iso2: "IS" },
  { code: "+91", country: "India", iso2: "IN" },
  { code: "+62", country: "Indonesia", iso2: "ID" },
  { code: "+98", country: "Iran", iso2: "IR" },
  { code: "+964", country: "Iraq", iso2: "IQ" },
  { code: "+353", country: "Ireland", iso2: "IE" },
  { code: "+972", country: "Israel", iso2: "IL" },
  { code: "+39", country: "Italy", iso2: "IT" },
  { code: "+225", country: "Ivory Coast", iso2: "CI" },
  { code: "+1-876", country: "Jamaica", iso2: "JM" },
  { code: "+81", country: "Japan", iso2: "JP" },
  { code: "+962", country: "Jordan", iso2: "JO" },
  { code: "+997", country: "Kazakhstan", iso2: "KZ" },
  { code: "+254", country: "Kenya", iso2: "KE" },
  { code: "+686", country: "Kiribati", iso2: "KI" },
  { code: "+850", country: "North Korea", iso2: "KP" },
  { code: "+82", country: "South Korea", iso2: "KR" },
  { code: "+965", country: "Kuwait", iso2: "KW" },
  { code: "+996", country: "Kyrgyzstan", iso2: "KG" },
  { code: "+856", country: "Laos", iso2: "LA" },
  { code: "+371", country: "Latvia", iso2: "LV" },
  { code: "+961", country: "Lebanon", iso2: "LB" },
  { code: "+266", country: "Lesotho", iso2: "LS" },
  { code: "+231", country: "Liberia", iso2: "LR" },
  { code: "+218", country: "Libya", iso2: "LY" },
  { code: "+423", country: "Liechtenstein", iso2: "LI" },
  { code: "+370", country: "Lithuania", iso2: "LT" },
  { code: "+352", country: "Luxembourg", iso2: "LU" },
  { code: "+853", country: "Macao", iso2: "MO" },
  { code: "+389", country: "North Macedonia", iso2: "MK" },
  { code: "+261", country: "Madagascar", iso2: "MG" },
  { code: "+265", country: "Malawi", iso2: "MW" },
  { code: "+60", country: "Malaysia", iso2: "MY" },
  { code: "+960", country: "Maldives", iso2: "MV" },
  { code: "+223", country: "Mali", iso2: "ML" },
  { code: "+356", country: "Malta", iso2: "MT" },
  { code: "+692", country: "Marshall Islands", iso2: "MH" },
  { code: "+222", country: "Mauritania", iso2: "MR" },
  { code: "+230", country: "Mauritius", iso2: "MU" },
  { code: "+52", country: "Mexico", iso2: "MX" },
  { code: "+691", country: "Micronesia", iso2: "FM" },
  { code: "+373", country: "Moldova", iso2: "MD" },
  { code: "+377", country: "Monaco", iso2: "MC" },
  { code: "+976", country: "Mongolia", iso2: "MN" },
  { code: "+382", country: "Montenegro", iso2: "ME" },
  { code: "+212", country: "Morocco", iso2: "MA" },
  { code: "+258", country: "Mozambique", iso2: "MZ" },
  { code: "+95", country: "Myanmar", iso2: "MM" },
  { code: "+264", country: "Namibia", iso2: "NA" },
  { code: "+674", country: "Nauru", iso2: "NR" },
  { code: "+977", country: "Nepal", iso2: "NP" },
  { code: "+31", country: "Netherlands", iso2: "NL" },
  { code: "+64", country: "New Zealand", iso2: "NZ" },
  { code: "+505", country: "Nicaragua", iso2: "NI" },
  { code: "+227", country: "Niger", iso2: "NE" },
  { code: "+234", country: "Nigeria", iso2: "NG" },
  { code: "+47", country: "Norway", iso2: "NO" },
  { code: "+968", country: "Oman", iso2: "OM" },
  { code: "+680", country: "Palau", iso2: "PW" },
  { code: "+970", country: "Palestine", iso2: "PS" },
  { code: "+507", country: "Panama", iso2: "PA" },
  { code: "+675", country: "Papua New Guinea", iso2: "PG" },
  { code: "+595", country: "Paraguay", iso2: "PY" },
  { code: "+63", country: "Philippines", iso2: "PH" },
  { code: "+48", country: "Poland", iso2: "PL" },
  { code: "+351", country: "Portugal", iso2: "PT" },
  { code: "+974", country: "Qatar", iso2: "QA" },
  { code: "+40", country: "Romania", iso2: "RO" },
  { code: "+7", country: "Russia", iso2: "RU" },
  { code: "+250", country: "Rwanda", iso2: "RW" },
  { code: "+1-869", country: "Saint Kitts and Nevis", iso2: "KN" },
  { code: "+1-758", country: "Saint Lucia", iso2: "LC" },
  { code: "+1-784", country: "Saint Vincent and the Grenadines", iso2: "VC" },
  { code: "+685", country: "Samoa", iso2: "WS" },
  { code: "+378", country: "San Marino", iso2: "SM" },
  { code: "+239", country: "Sao Tome and Principe", iso2: "ST" },
  { code: "+966", country: "Saudi Arabia", iso2: "SA" },
  { code: "+221", country: "Senegal", iso2: "SN" },
  { code: "+381", country: "Serbia", iso2: "RS" },
  { code: "+248", country: "Seychelles", iso2: "SC" },
  { code: "+232", country: "Sierra Leone", iso2: "SL" },
  { code: "+65", country: "Singapore", iso2: "SG" },
  { code: "+421", country: "Slovakia", iso2: "SK" },
  { code: "+386", country: "Slovenia", iso2: "SI" },
  { code: "+677", country: "Solomon Islands", iso2: "SB" },
  { code: "+252", country: "Somalia", iso2: "SO" },
  { code: "+27", country: "South Africa", iso2: "ZA" },
  { code: "+211", country: "South Sudan", iso2: "SS" },
  { code: "+34", country: "Spain", iso2: "ES" },
  { code: "+94", country: "Sri Lanka", iso2: "LK" },
  { code: "+249", country: "Sudan", iso2: "SD" },
  { code: "+597", country: "Suriname", iso2: "SR" },
  { code: "+268", country: "Eswatini", iso2: "SZ" },
  { code: "+46", country: "Sweden", iso2: "SE" },
  { code: "+41", country: "Switzerland", iso2: "CH" },
  { code: "+963", country: "Syria", iso2: "SY" },
  { code: "+886", country: "Taiwan", iso2: "TW" },
  { code: "+992", country: "Tajikistan", iso2: "TJ" },
  { code: "+255", country: "Tanzania", iso2: "TZ" },
  { code: "+66", country: "Thailand", iso2: "TH" },
  { code: "+228", country: "Togo", iso2: "TG" },
  { code: "+676", country: "Tonga", iso2: "TO" },
  { code: "+1-868", country: "Trinidad and Tobago", iso2: "TT" },
  { code: "+216", country: "Tunisia", iso2: "TN" },
  { code: "+90", country: "Turkey", iso2: "TR" },
  { code: "+993", country: "Turkmenistan", iso2: "TM" },
  { code: "+688", country: "Tuvalu", iso2: "TV" },
  { code: "+256", country: "Uganda", iso2: "UG" },
  { code: "+380", country: "Ukraine", iso2: "UA" },
  { code: "+971", country: "United Arab Emirates", iso2: "AE" },
  { code: "+44", country: "United Kingdom", iso2: "GB" },
  { code: "+598", country: "Uruguay", iso2: "UY" },
  { code: "+998", country: "Uzbekistan", iso2: "UZ" },
  { code: "+678", country: "Vanuatu", iso2: "VU" },
  { code: "+379", country: "Vatican City", iso2: "VA" },
  { code: "+58", country: "Venezuela", iso2: "VE" },
  { code: "+84", country: "Vietnam", iso2: "VN" },
  { code: "+967", country: "Yemen", iso2: "YE" },
  { code: "+260", country: "Zambia", iso2: "ZM" },
  { code: "+263", country: "Zimbabwe", iso2: "ZW" },
];

export const REFERRAL_URLS = {
  Peru: "https://referral.onedge.co/access/?programidentifier=37030a9c-3397-4038-8c27-700f6a5c8a78&firstname=%7B%7Bcontact.firstname%7D%7D&lastname=%7B%7Bcontact.lastname%7D%7D&email=%7B%7Bcontact.email%7D%7D&utm_medium=direct&utm_source=employee-portal",
  Pakistan:
    "https://referral.onedge.co/access/?programidentifier=88c125e7-ef6f-451d-88e6-c32f065730e6&firstname=%7B%7Bcontact.firstname%7D%7D&lastname=%7B%7Bcontact.lastname%7D%7D&email=%7B%7Bcontact.email%7D%7D&utm_medium=direct&utm_source=employee-portal",
};

export const EDUCATION_LEVELS = [
  {
    value: "No Formal Education",
    label: "No Formal Education",
  },
  {
    value: "Primary Education",
    label: "Primary Education",
  },
  {
    value: "Secondary Education or High School",
    label: "Secondary Education or High School",
  },
  {
    value: "Vocational Qualification",
    label: "Vocational Qualification",
  },
  {
    value: "Associate Degree",
    label: "Associate Degree",
  },
  {
    value: "Bachelor's Degree",
    label: "Bachelor's Degree",
  },
  {
    value: "Master's Degree",
    label: "Master's Degree",
  },
  {
    value: "Professional Degree (e.g., JD, MD)",
    label: "Professional Degree (e.g., JD, MD)",
  },
  {
    value: "Doctorate or Higher",
    label: "Doctorate or Higher",
  },
];

export const EMPLOYEMENT_TYPE = [
  {
    value: "Full Time",
    label: "Full Time",
  },
  {
    value: "Part Time",
    label: "Part Time",
  },
];

export const INACTIVE_REASONS = [
  {
    value: "Resigned",
    label: "Resigned",
  },
  {
    value: "Terminated",
    label: "Terminated",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const MAX_CHARACTERS_MESSAGE = "Max 260 characters";
