import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    const handleTitleUpdate = () => {
      setTimeout(() => {
        window.analytics?.page(document.title || "Unknown Page", {
          hash: location.hash ?? "",
        });
      }, 0);
    };

    handleTitleUpdate();
  }, [location]);

  return null;
}
