import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Avatar } from "@mui/material";

import Button from "components/common/Button";
import { CardHeader } from "components/common/Card";
import PageLoader from "components/common/PageLoader";
import Dialog, { DialogActions } from "components/common/Dialog";

import { DeleteUser, ResetDeleteUser } from "store/actions/Settings/DeleteUser";
import { ManageUser } from "store/actions/Settings/ManageUser";

import { baseUrl } from "util/APIBaseUrl";
import { WarningAmberOutlined } from "@mui/icons-material";

import "./remove-user-v2.scss";

const avatarSx = {
  width: "32px",
  height: "32px",
  marginTop: "4px",
  borderRadius: "16px",
  textAlign: "center",
};

function RemoveUser(props) {
  const dispatch = useDispatch();
  const { openRemoveUser, editableUser, handleCloseRemoveUser, setOpenRemoveUser, customerId, setSearchEmployee } =
    props;
  const [profileUrl, setProfileUrl] = useState(null);

  const deleteUser = useSelector((state) => state.DeleteUser.deleteUser);
  const loadingDeleteUser = useSelector((state) => state.DeleteUser.loadingDeleteUser);

  const handleDeleteUser = (userId) => {
    setOpenRemoveUser(false);
    if (userId) {
      const deleteUserRequest = `v2/customerUser/${userId}`;
      dispatch(
        DeleteUser({
          request: deleteUserRequest,
        })
      );
      if (setSearchEmployee) {
        setSearchEmployee("");
      }
    }
  };

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (editableUser && Object.keys(editableUser).length && Object.keys(loggedInUser).length && loggedInUser.userId) {
      const userProfileUrl = `${baseUrl}/customerUser/${editableUser?.userId}/logo?id=${editableUser?.pictureVersion}`;
      setProfileUrl(userProfileUrl);
    }
  }, [editableUser]);

  useEffect(() => {
    let loggedInUser = {};
    let manageUserRequest = "";
    const userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));

    loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (Object.keys(deleteUser).length && deleteUser?.ok && deleteUser?.payload) {
      if (Object.keys(loggedInUser).length && loggedInUser.userId) {
        if (loggedInUser?.type === "customer") {
          manageUserRequest = `v2/customer/${userAdditionalInfo?.customerId}/customerUsers`;
        } else if (loggedInUser?.type === "edge") {
          manageUserRequest = `v2/customer/${customerId}/customerUsers`;
        }

        dispatch(
          ManageUser({
            request: manageUserRequest,
            rowsPerPage: 10,
            page: 0,
            sortBy: {},
          })
        );
      }
      setOpenRemoveUser(false);
      dispatch(ResetDeleteUser({}));
    }
  }, [deleteUser]);

  return (
    <>
      {loadingDeleteUser && <PageLoader />}
      <Dialog
        open={openRemoveUser}
        onClose={handleCloseRemoveUser}
        title={
          <div className="dialog-header-wrapper">
            <WarningAmberOutlined sx={{ fontSize: 28, color: "#E3382D" }} />
          </div>
        }
        content={
          <div className="space-y-1">
            <p className="heading-h2-semibold">Are you sure you want to remove the following user?</p>
            <CardHeader
              avatar={<Avatar sx={avatarSx} src={profileUrl} />}
              title={<p className="capitalize">{`${editableUser?.firstName} ${editableUser?.lastName}`}</p>}
              subheader={editableUser?.email}
              sx={{
                ".MuiCardHeader-avatar": {
                  marginRight: "8px",
                  padding: 0,
                },
                backgroundColor: "#F7F7F7",
              }}
            />
          </div>
        }
        actions={
          <DialogActions
            action1={<Button label="Cancel" size="large" variant="text" onClick={() => setOpenRemoveUser(false)} />}
            action2={
              <Button
                label="Yes, remove"
                size="large"
                color="error"
                onClick={() => handleDeleteUser(editableUser?.userId)}
              />
            }
          />
        }
        size="xs"
      />
    </>
  );
}

export default RemoveUser;
