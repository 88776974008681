import { parsePhoneNumber } from "libphonenumber-js";
import * as yup from "yup";

const getValidationMessage = (phoneNo) => {
  if (!phoneNo) {
    return true;
  }
  const message = "Invalid phone number for the selected country";
  try {
    const parsedNumber = parsePhoneNumber(phoneNo);
    if (!parsedNumber || !parsedNumber.isValid()) {
      return message;
    }
  } catch (e) {
    return message;
  }
  return true;
};

const validatePhoneFormat = (value) => {
  const validationMessage = getValidationMessage(value);
  return validationMessage === true || new yup.ValidationError(validationMessage, null, "phoneNumber");
};

export default validatePhoneFormat;
