import React, { useState } from "react";

import Dialog, { DialogTitle, DialogContent, DialogActions } from "components/common/Dialog";
import Text from "components/common/Text";
import Select from "components/common/Select";
import Button from "components/common/Button";

import { INACTIVE_REASONS, MAX_CHARACTERS_MESSAGE } from "constants";

import MarkEmployeeAsInactiveSchema from "validations/Admin/MarkEmployeeAsInactiveSchema";
import { markEmployeeAsInactive } from "services/Admin/Dashboard";

import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar, SnackbarContent } from "@mui/material";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import "./MarkInActiveWithReason.scss";

export default function MarkInActiveWithReason({
  selectedId,
  open,
  setOpen,
  employeeFullName,
  getEmployeeListHandler,
  status,
}) {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);

  const isEmployeeInactive = status === "inactive";

  const methods = useForm({
    resolver: yupResolver(MarkEmployeeAsInactiveSchema),
    mode: "onChange",
    defaultValues: {
      reason: "",
      comments: "",
    },
  });

  const handleClose = () => {
    setOpen(false);
    methods.reset();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const { handleSubmit, watch, formState } = methods;
  const { isValid } = formState;

  const reason = watch("reason");

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await markEmployeeAsInactive(selectedId, data);
      setLoading(false);
      setOpen(false);
      setIsSuccess(true);
      setSnackbarOpen(true);
      methods.reset();
      getEmployeeListHandler();
    } catch (error) {
      console.error("Error marking employee as inactive:", error);
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="mark-inactive-title"
        size="sm"
        fullWidth={false}
        title={
          <>
            <div className="icon-container">
              <WarningAmberOutlinedIcon className="inactive-employee-amber-icon" />
            </div>
            {!isEmployeeInactive && <DialogTitle title="Mark as Inactive" />}
          </>
        }
        content={
          isEmployeeInactive ? (
            <div className="employee-already-inactive-wrapper">
              <p className="mark-inactive-message">
                <span>{employeeFullName}</span> is already marked as <span>Inactive</span>.
              </p>
            </div>
          ) : (
            <>
              <p className="mark-inactive-message">
                Are you sure you want to mark <span>{employeeFullName}</span> as <span>Inactive</span>?
              </p>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="inactive-modal-content">
                    <DialogContent>
                      <Select
                        label="Reason for marking Inactive"
                        options={INACTIVE_REASONS}
                        name="reason"
                        placeholder="Select"
                        data-testid="reason-select"
                      />

                      <div className="mt-1">
                        <Text
                          name="comments"
                          multiline
                          rows={3}
                          label={`Additional comments ${reason === "Other" ? "" : "(optional)"}`}
                          size="medium"
                        />
                      </div>
                      <p className="inactive-modal-max-words">{MAX_CHARACTERS_MESSAGE}</p>
                    </DialogContent>
                  </div>
                </form>
              </FormProvider>
            </>
          )
        }
        actions={
          <DialogActions
            action1={
              <Button
                variant="text"
                label={isEmployeeInactive ? "Close" : "Cancel"}
                size="large"
                onClick={handleClose}
              />
            }
            action2={
              !isEmployeeInactive && (
                <Button
                  onClick={handleSubmit(onSubmit)}
                  label="Yes, Mark as inactive"
                  size="large"
                  color="error"
                  isDisabled={!isValid || loading}
                />
              )
            }
          />
        }
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <SnackbarContent
          message={
            <span className="inactive-employee-message">
              {isSuccess ? <DoneIcon className="mark-inactive-done-icon" /> : null}
              {isSuccess ? (
                <p className="custom-snackbar-message">
                  <span>{employeeFullName}</span> has been marked as <span>Inactive</span>
                </p>
              ) : null}
            </span>
          }
          action={<CloseIcon onClick={handleSnackbarClose} />}
          className="custom-snackbar-content"
        />
      </Snackbar>
    </>
  );
}
