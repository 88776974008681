const getUserContext = () => {
  const loggedInUser = localStorage.getItem("loggedInUser");

  if (loggedInUser) {
    const user = JSON.parse(loggedInUser);
    return {
      key: user.email,
      name: user.name,
      email: user.email,
    };
  }

  let anonymousKey = localStorage.getItem("anonymousKeyForLD");

  if (!anonymousKey) {
    anonymousKey = `anonymous-${Date.now()}`;
    localStorage.setItem("anonymousKeyForLD", anonymousKey);
  }

  return {
    key: anonymousKey,
    anonymous: true,
  };
};
export default getUserContext;
