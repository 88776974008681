import React, { useState, useRef, useMemo, useEffect } from "react";
import { useFormContext, Controller } from "react-hook-form";
import PropTypes from "prop-types";
import { parsePhoneNumber } from "libphonenumber-js";

import { ListItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ReactCountryFlag from "react-country-flag";

import { COUNTRY_CODES } from "constants";

function PhoneInput({
  name,
  label,
  helperText,
  placeholder,
  value: propValue,
  onChange: propOnChange,
  error: propError,
  size = "small",
  variant = "outlined",
  disabled = false,
  isOptional = false,
  defaultCountry = "Pakistan",
  ...other
}) {
  const formContext = useFormContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY_CODES[0]);
  const [phoneNumber, setPhoneNumber] = useState("");

  const textFieldRef = useRef(null);

  useEffect(() => {
    if (defaultCountry) {
      const countryCode = COUNTRY_CODES.find((country) => country.country === defaultCountry);
      if (countryCode) {
        setSelectedCountry(countryCode);
      }
    } else {
      setSelectedCountry(COUNTRY_CODES[0]);
    }
  }, [defaultCountry]);

  const handleClick = (event) => {
    setSearchTerm("");
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    const formattedValue = phoneNumber ? `${country.code} ${phoneNumber}` : "";
    if (formContext) {
      formContext.setValue(name, formattedValue);
      formContext.trigger(name);
    }
    if (propOnChange) {
      propOnChange(formattedValue);
    }
    handleClose();
  };

  const preferredCountries = useMemo(() => COUNTRY_CODES.filter((country) => country.preferred), []);

  const filteredCountries = useMemo(
    () =>
      COUNTRY_CODES.filter(
        (country) =>
          country.country.toLowerCase().includes(searchTerm.toLowerCase()) || country.code.includes(searchTerm)
      ),
    [searchTerm]
  );

  const getCountryCodeFromPhoneNumber = (phoneNum) => {
    try {
      const parsedNumber = parsePhoneNumber(phoneNum);
      return COUNTRY_CODES.find((country) => country.iso2 === parsedNumber.country);
    } catch (error) {
      console.error("Failed to parse phone number:", error);
      return null;
    }
  };

  useEffect(() => {
    const initialValue = propValue || (formContext && formContext.getValues && formContext.getValues()[name]);
    if (initialValue) {
      const country = getCountryCodeFromPhoneNumber(initialValue);
      if (country) {
        setSelectedCountry(country);
      }
      setPhoneNumber(initialValue.split(" ")[1] || "");
    }
  }, [propValue, name]);

  const renderPhoneInput = (field = {}, fieldError) => {
    const [, phoneNumb] = (field.value || "").split(" ");
    const currentValue = phoneNumb || "";

    return (
      <FormControl fullWidth className="form-control" data-testid="form-control">
        <InputLabel
          shrink
          htmlFor={name}
          className="input-label text-left paragraph text-md font-medium line-height-md mb-025 pos-relative non-transform"
        >
          {`${label} ${isOptional ? "(optional)" : ""}`}
        </InputLabel>
        <div className="ant-text-component">
          <TextField
            {...field}
            id={name}
            name={name}
            disabled={disabled}
            error={!!fieldError}
            helperText={fieldError ? fieldError.message : helperText}
            value={currentValue}
            size={size}
            variant={variant}
            fullWidth
            ref={textFieldRef}
            onChange={(e) => {
              const newPhoneNumber = e.target.value.replace(/[^0-9]/g, "");
              setPhoneNumber(newPhoneNumber);
              const newValue = newPhoneNumber ? `${selectedCountry.code} ${newPhoneNumber}` : "";
              field.onChange(newValue);

              if (formContext) {
                formContext.trigger(name);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div
                    onClick={handleClick}
                    className="country-dropdown-text paragraph font-regular text-lg line-height-xl"
                    role="button"
                    aria-haspopup="listbox"
                    aria-expanded={Boolean(anchorEl)}
                  >
                    <ReactCountryFlag countryCode={selectedCountry.iso2} svg className="country-flag" />
                    {selectedCountry.code}
                    <ArrowDropDownIcon
                      className={`country-code-arrow ${anchorEl && !disabled ? "inverted-chevron-icon" : ""}`}
                    />
                  </div>
                </InputAdornment>
              ),
              classes: {
                root: `custom-text-input-root text-field-padding text-field-input-${size} ${disabled ? "disabled-input" : ""}`,
                input: "paragraph font-regular text-lg line-height-xl flex mr-15",
                focused: "custom-text-input-focused",
                error: "custom-text-input-error",
              },
              "data-testid": "text-field",
            }}
            InputLabelProps={{
              shrink: true,
            }}
            FormHelperTextProps={{
              classes: {
                root: "helper-text paragraph text-sm font-regular line-height-sm error-text-color ml-0",
              },
            }}
            {...other}
          />
        </div>
      </FormControl>
    );
  };

  const countryCodePopover = (
    <Popover
      open={Boolean(anchorEl) && !disabled}
      anchorEl={anchorEl}
      onClose={handleClose}
      className="countries-dropdown"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          width: textFieldRef.current ? `${textFieldRef.current.clientWidth}px` : undefined,
          maxHeight: "248px",
          marginLeft: textFieldRef.current
            ? `-${anchorEl?.getBoundingClientRect().left - textFieldRef?.current?.getBoundingClientRect().left}px`
            : undefined,
          marginTop: size === "small" ? "7px" : "15px",
        },
      }}
    >
      <div className="country-search-input">
        <InputBase
          placeholder="Country or code"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          startAdornment={<SearchIcon className="country-search-icon" />}
          fullWidth
          className="paragraph font-regular text-lg line-height-xl"
        />
      </div>
      <Divider className="country-divider" />
      <List className="country-list" role="listbox">
        {!searchTerm && (
          <>
            {preferredCountries.map((country) => (
              <ListItem
                key={country.code}
                onClick={() => {
                  handleCountrySelect(country);
                }}
                className={`country-list-item ${country.iso2 === selectedCountry.iso2 ? "selected-country-item" : ""}`}
                role="option"
                aria-selected={country.iso2 === selectedCountry.iso2}
              >
                <div className="country-info">
                  <div className="country-flag-container">
                    <ReactCountryFlag countryCode={country.iso2} svg className="country-flag" />
                  </div>
                  <div title={country.country} className="country-name flex">
                    <span className="paragraph font-regular text-lg line-height-xl phone-input-country-name">
                      {country.country}
                    </span>
                    <span className="paragraph font-regular text-lg line-height-xl country-code-text">
                      {country.code}
                    </span>
                  </div>
                </div>
              </ListItem>
            ))}
            <Divider className="country-divider" />
          </>
        )}

        {filteredCountries.length > 0 ? (
          filteredCountries
            .filter((country) => !country.preferred)
            .map((country) => (
              <ListItem
                key={country.code}
                onClick={() => handleCountrySelect(country)}
                className={`country-list-item ${country.iso2 === selectedCountry.iso2 ? "selected-country-item" : ""}`}
                role="option"
                aria-selected={country.iso2 === selectedCountry.iso2}
              >
                <div className="country-info">
                  <div className="country-flag-container">
                    <ReactCountryFlag countryCode={country.iso2} svg className="country-flag" />
                  </div>
                  <div title={country.country} className="country-name flex">
                    <span className="paragraph font-regular text-lg line-height-xl phone-input-country-name">
                      {country.country}
                    </span>
                    <span className="paragraph font-regular text-lg line-height-xl country-code-text">
                      {country.code}
                    </span>
                  </div>
                </div>
              </ListItem>
            ))
        ) : (
          <p className="paragraph no-result-phone-input">No results found</p>
        )}
      </List>
    </Popover>
  );

  return (
    <div className="phone-input-wrapper" style={{ position: "relative", zIndex: 1 }}>
      {formContext && !propValue && !propOnChange ? (
        <Controller
          name={name}
          control={formContext.control}
          defaultValue=""
          render={({ field, fieldState: { error } }) => renderPhoneInput(field, error)}
        />
      ) : (
        renderPhoneInput({ value: propValue, onChange: propOnChange }, propError ? { message: helperText } : null)
      )}
      {countryCodePopover}
    </div>
  );
}

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  disabled: PropTypes.bool,
  isOptional: PropTypes.bool,
};

PhoneInput.defaultProps = {
  helperText: "",
  placeholder: "Enter phone number",
  value: "",
  onChange: undefined,
  error: false,
  size: "small",
  variant: "outlined",
  disabled: false,
  isOptional: false,
};

export default PhoneInput;
