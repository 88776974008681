import React from "react";
import PropTypes from "prop-types";
import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from "@mui/material";

import "./Dialog.scss";

function Dialog({ open, onClose, title, content, actions, size, fullWidth = true }) {
  return (
    <MuiDialog open={open} onClose={onClose} maxWidth={size} fullWidth={fullWidth}>
      <div className="custom-dialog">
        {title && title}
        {content && content}
        {actions && actions}
      </div>
    </MuiDialog>
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  content: PropTypes.node,
  actions: PropTypes.node,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
};

Dialog.defaultProps = {
  title: null,
  content: null,
  actions: null,
  size: "md",
};

function DialogTitle({ title }) {
  return <MuiDialogTitle className="custom-dialog-title">{title}</MuiDialogTitle>;
}

DialogTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

function DialogContent({ children, dividers }) {
  return (
    <MuiDialogContent className={`custom-dialog-content ${dividers ? "with-dividers" : ""}`}>
      {children}
    </MuiDialogContent>
  );
}

DialogContent.propTypes = {
  children: PropTypes.node.isRequired,
  dividers: PropTypes.bool,
};

DialogContent.defaultProps = {
  dividers: false,
};

function DialogActions({ action1, action2, action3 }) {
  return (
    <MuiDialogActions className="custom-dialog-actions">
      {action1}
      {action2}
      {action3}
    </MuiDialogActions>
  );
}

DialogActions.propTypes = {
  action1: PropTypes.node,
  action2: PropTypes.node,
  action3: PropTypes.node,
};

DialogActions.defaultProps = {
  action1: null,
  action2: null,
  action3: null,
};

export default Dialog;
export { DialogTitle, DialogContent, DialogActions };
