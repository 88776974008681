import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import "./tooltip.scss";

function CustomTooltip({ content, children, direction = "top", arrow = true, className = "", ...other }) {
  return (
    <Tooltip
      title={content}
      placement={direction}
      arrow={arrow}
      classes={{
        tooltip: "custom-tooltip",
        arrow: "custom-arrow",
      }}
      className={className}
      {...other}
    >
      {children}
    </Tooltip>
  );
}

CustomTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf([
    "top",
    "bottom",
    "left",
    "right",
    "top-start",
    "top-end",
    "bottom-start",
    "bottom-end",
    "left-start",
    "left-end",
    "right-start",
    "right-end",
  ]),
  arrow: PropTypes.bool,
  className: PropTypes.string,
};

CustomTooltip.defaultProps = {
  direction: "top",
  arrow: true,
  className: "",
};

export default CustomTooltip;
