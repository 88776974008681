import * as Yup from "yup";

export default Yup.object().shape({
  reason: Yup.string().required("Reason for marking Inactive is required"),
  comments: Yup.string()
    .nullable()
    .max(260, "Comments cannot exceed 260 characters")
    .when("reason", (inactiveReason, schema) => {
      return inactiveReason[0] == "Other"
        ? schema.required("Comments are required when reason is 'Other'")
        : schema.notRequired();
    }),
});
