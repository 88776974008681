import React from "react";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import PersonIcon from "@mui/icons-material/Person";
import { styled } from "@mui/system";

const sizeMap = {
  xs: 18,
  sm: 24,
  md: 32,
  lg: 40,
};

const iconSizeMap = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
};

const StyledAvatar = styled(Avatar)(({ size, hasImage, backgroundColor }) => ({
  width: sizeMap[size],
  height: sizeMap[size],
  fontSize: size === "xs" ? "0.75rem" : "1rem",
  backgroundColor: hasImage ? "transparent" : backgroundColor,
}));

const StyledPersonIcon = styled(PersonIcon)(({ size }) => ({
  fontSize: iconSizeMap[size],
}));

const getClassNameBySize = (size) => {
  switch (size) {
    case "xs":
      return "para-body-xs-regular-important";
    case "sm":
      return "para-body-s-regular-important";
    case "md":
      return "para-body-m-regular-important";
    case "lg":
      return "para-body-l-regular-important";
    default:
      return "";
  }
};

function CustomAvatar({ alt, src, children, variant, size, backgroundColor }) {
  return (
    <StyledAvatar
      alt={alt}
      src={src}
      variant={variant}
      size={size}
      className={getClassNameBySize(size)}
      hasImage={!!src}
      backgroundColor={backgroundColor}
    >
      {src ? null : children || <StyledPersonIcon size={size} />}
    </StyledAvatar>
  );
}

CustomAvatar.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.oneOf(["circular", "rounded", "square"]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  backgroundColor: PropTypes.string,
};

CustomAvatar.defaultProps = {
  alt: "",
  src: "",
  children: null,
  variant: "circular",
  size: "md",
  backgroundColor: "#C8C8C8",
};

export default CustomAvatar;
