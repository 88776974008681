/* eslint-disable */
import { useEffect } from "react";

const loadSegment = (writeKey) => {
  !(function () {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error("Segment snippet included twice.");
      else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "screen",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
          "register",
        ];
        analytics.factory = function (method) {
          return function () {
            const args = Array.prototype.slice.call(arguments);
            analytics.push([method].concat(args));
          };
        };
        for (let i = 0; i < analytics.methods.length; i++) {
          const key = analytics.methods[i];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key) {
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.async = !0;
          script.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
          const first = document.getElementsByTagName("script")[0];
          first.parentNode.insertBefore(script, first);
        };
        analytics.load(writeKey);

        window.analytics?.page(document.title || "Unknown Page", {
          hash: window.location.hash ?? "",
        });
      }
  })();
};

function SegmentScript({ environment }) {
  useEffect(() => {
    let segmentKey = "";
    switch (environment) {
      case "production":
        segmentKey = "Y29mlBAleANFoIYsabKEmZSikJYKB00Z";
        break;
      case "staging":
        segmentKey = "o2xQ0zsnfGHiwFQClS9GDGu76PVusMSi";
        break;
      case "development":
        segmentKey = "b0AGGJKvbTKygLIsfTZefuOjdNL2Z5MA";
        break;
      default:
        console.warn("No Segment key for this environment");
    }

    if (segmentKey) {
      loadSegment(segmentKey);
    }
  }, [environment]);

  return null;
}

export default SegmentScript;
