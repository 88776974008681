import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Button from "components/common/Button";

import "../Document.scss";

function DocumentTopBar({ folderName, onBackClicked, showAddButton, onAddButtonClick }) {
  return (
    <div className="justify-between flex flex-row mb-05">
      <div className="flex flex-row">
        <div onClick={() => onBackClicked()} className="cursor-pointer">
          <ArrowBackIcon />
        </div>
        <p className="heading-h4-semibold ml-1">{folderName}</p>
      </div>
      <div>
        {showAddButton && (
          <Button
            className="top-add-button"
            label="+ Add Document"
            color="secondary"
            onClick={() => {
              onAddButtonClick();
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DocumentTopBar;
