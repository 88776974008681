import { call, put, takeEvery } from "redux-saga/effects";

import { EmployeePeersSuccess, EmployeePeersFailure } from "../../actions/EmployeePeers";

import { EMPLOYEE_PEERS } from "../../constants/EmployeePeers";

import getRequest from "../../../util/APIHelperGet";
import { baseUrl } from "../../../util/APIBaseUrl";

export const fetchEmployeePeersRequest = async (actions) => {
  const url = actions?.payload?.isFlagTeamManagement ? `${baseUrl}/v2` : baseUrl;

  return await getRequest(`${url}/${actions.payload.url}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

function* fetchEmployeePeers(params) {
  try {
    const response = yield call(fetchEmployeePeersRequest, params);
    yield put(EmployeePeersSuccess(response));
  } catch (error) {
    console.error(error);
    yield put(EmployeePeersFailure(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(EMPLOYEE_PEERS, fetchEmployeePeers);
}
