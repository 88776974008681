import * as Yup from "yup";
import validatePhoneFormat from "validations/PhoneNumberValidation";

export default Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First Name must be at least 2 characters long")
    .max(50, "First Name cannot be longer than 50 characters")
    .required("First Name is required"),

  lastName: Yup.string()
    .min(2, "Last Name must be at least 2 characters long")
    .max(50, "Last Name cannot be longer than 50 characters")
    .required("Last Name is required"),

  email: Yup.string().email("Invalid email").required("Email is required").nullable(),
  phoneNumber: Yup.string().required("Phone number is required").test(validatePhoneFormat),
  permissions: Yup.string().required("Permission is required"),
});
