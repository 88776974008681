import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getFormattedDateAndTime, getValue } from "components/Screens/CommonUtils";
import TableComponent from "components//common/TableComponent";
import { useDebounce } from "components/useDebounce";
import AvatarIcon from "components/Icons/AvatarIcon";
import Text from "components/common/Text";
import AddUser from "components/Screens/SettingV2/AddUser/AddUserV2";

import CustomButton from "components/common/Button";
import PageLoader from "components/common/PageLoader";

import { ManageUser } from "store/actions/Settings/ManageUser";
// import { ResetAddUser } from "store/actions/Settings/AddUser";
import RemoveUser from "components/Screens/SettingV2/UserPermissions/RemoveUserV2";

import SearchIcon from "@mui/icons-material/Search";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";

import { baseUrl } from "util/APIBaseUrl";

import "./UserList.scss";

const avatarSize = {
  width: "32px",
  height: "32px",
  borderRadius: "100px",
};

function UserList(props) {
  const { customerId } = props;
  const manageUserRequest = `v2/customer/${customerId}/customerUsers`;

  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState({});
  const [rowsData, setRowsData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [addOrEdit, setAddOrEdit] = useState({ open: false, mode: null, user: null });
  const [openRemoveUser, setOpenRemoveUser] = useState(false);

  const manageUser = useSelector((state) => state.ManageUser.manageUser);
  const loadingManageUser = useSelector((state) => state.ManageUser.loadingManageUser);

  const debounceSearchEmployee = useDebounce(searchEmployee, 500);

  const [profileUrls, setProfileUrls] = useState({});

  const getPermissionTitle = (permission, mainUser) => {
    const permissionMap = {
      CustomerAdmin: mainUser === true ? "Account Owner" : "Customer Admin",
      CustomerEmployee: "Customer User",
    };
    return getValue(permissionMap[permission] || permission || "N/A");
  };

  const columns = [
    {
      id: "First_Name",
      label: "User",
      minWidth: 150,
      sortable: true,
      render: (value, row) => (
        <div className="flex items-center gap-1">
          <AvatarIcon shape="circle" size={avatarSize} source={profileUrls[row.id]} />
          <div className="para-body-m-regular">{value}</div>
        </div>
      ),
    },
    {
      id: "Email",
      label: "Email Address",
      minWidth: 150,
      sortable: false,
      render: (value) => <div className="para-body-m-regular">{value}</div>,
    },
    {
      id: "Phone_Number",
      label: "Phone Number",
      minWidth: 120,
      sortable: false,
      render: (value) => <div className="para-body-m-regular">{value}</div>,
    },
    {
      id: "Country",
      label: "Country",
      minWidth: 100,
      sortable: true,
      render: (value) => <div className="para-body-m-regular">{value}</div>,
    },
    {
      id: "Permission",
      label: "Permission",
      minWidth: 120,
      sortable: false,
      render: (value, row) => (
        <div className="para-body-m-regular">
          {getPermissionTitle(row.originalData?.permissions?.[0], row.originalData?.mainUser)}
        </div>
      ),
    },
    {
      id: "Last_Active",
      label: "Last Active",
      minWidth: 150,
      sortable: true,
      render: (value) => <div className="para-body-m-regular">{value}</div>,
    },

    {
      id: "actions",
      label: "Action",
      minWidth: 100,
      type: "action",
      sortable: false,
      actions: [
        {
          icon: <EditOutlinedIcon disabled fontSize="medium" />,
          label: "Edit",
        },
        {
          icon: <DeleteOutlinedIcon fontSize="medium" />,
          label: "Delete",
          disabled: (row) => row.originalData?.mainUser === true,
        },
      ],
    },
  ];

  const handleSearchEmployee = (event) => {
    setSearchEmployee(event.target.value);
    setPage(0);
  };

  const handleSort = (field, direction) => {
    setSortBy({ orderBy: field, order: direction });
    dispatch(
      ManageUser({
        request: manageUserRequest,
        rowsPerPage,
        page,
        sortBy: {
          field,
          direction,
        },
      })
    );
  };

  const handleAction = (action, row) => {
    if (action.label === "Edit") {
      setAddOrEdit({ open: true, mode: "editUser", user: row.originalData });
    } else if (action.label === "Delete") {
      setAddOrEdit({ open: false, mode: "deleteUser", user: row.originalData });
      setOpenRemoveUser(true);
    }
  };

  const handleCloseRemoveUser = () => {
    setOpenRemoveUser(false);
    setAddOrEdit({ open: false, mode: null, user: null });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(
      ManageUser({
        request: manageUserRequest,
        rowsPerPage,
        page: newPage,
        searchKeyword: debounceSearchEmployee,
        sortBy: {
          field: sortBy?.orderBy,
          direction: sortBy?.order,
        },
      })
    );
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    dispatch(
      ManageUser({
        request: manageUserRequest,
        rowsPerPage: newRowsPerPage,
        page: 0,
        searchKeyword: debounceSearchEmployee,
        sortBy: {
          field: sortBy?.orderBy,
          direction: sortBy?.order,
        },
      })
    );
  };

  const fetchUsers = useCallback(() => {
    dispatch(
      ManageUser({
        request: manageUserRequest,
        rowsPerPage,
        page,
        searchKeyword: debounceSearchEmployee,
        sortBy: {
          field: sortBy?.orderBy,
          direction: sortBy?.order,
        },
      })
    );
  }, [manageUserRequest, rowsPerPage, page, debounceSearchEmployee, sortBy]);

  useEffect(() => {
    if (!addOrEdit?.open) {
      fetchUsers();
    }
  }, [debounceSearchEmployee, addOrEdit]);

  useEffect(() => {
    const Data = [];

    if (manageUser?.ok) {
      manageUser?.payload?.list?.map((user) => {
        Data.push({
          id: user?.userId,
          originalData: user,
          First_Name: `${getValue(user?.firstName)} ${getValue(user?.lastName)}`,
          Email: getValue(user?.email),
          Phone_Number: getValue(user?.phoneNumber),
          Country: getValue(user?.country || "N/A"),
          Permission: getValue(user?.permissions[0] || "N/A"),
          Last_Active: user?.lastActive ? getFormattedDateAndTime(user?.lastActive) : "N/A",
          isAccountOwner: user?.mainUser === true,
        });
        return "";
      });
    }
    if (!sortBy.orderBy && !sortBy.order) {
      Data.sort((a, b) => {
        if (a.isAccountOwner && !b.isAccountOwner) return -1;
        if (!a.isAccountOwner && b.isAccountOwner) return 1;
        return 0;
      });
    }

    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

    if (manageUser?.ok && loggedInUser?.userId) {
      const newProfileUrls = {};

      manageUser?.payload.list?.forEach((user) => {
        if (user?.userId && user?.pictureVersion) {
          const userProfileUrl = `${baseUrl}/CustomerUser/${user.userId}/logo?id=${user.pictureVersion}`;
          newProfileUrls[user.userId] = userProfileUrl;
        }
      });

      setProfileUrls(newProfileUrls);
    }

    setRowsData(Data);
  }, [manageUser, customerId]);

  return (
    <>
      {loadingManageUser && <PageLoader />}
      <div className="flex flex-col">
        <div className="user-list-container bg-white">
          {!addOrEdit?.open ? (
            <div>
              <div className="flex justify-between items-center gap-1 mb-125">
                <div className="flex items-center gap-05">
                  <PeopleAltOutlinedIcon className="users-icons-size" />
                  <div className="heading-h3-semibold">Users ({rowsData?.length || 0})</div>
                </div>
                <div className="flex items-center gap-1">
                  <Text
                    id="search-emp"
                    variant="outlined"
                    placeholder="User name or email address"
                    size="small"
                    sx={{
                      minWidth: "336px",
                    }}
                    name="user"
                    value={searchEmployee}
                    onChange={handleSearchEmployee}
                    startIcon={<SearchIcon className="action-icon-color action-icons-size" />}
                  />

                  <CustomButton
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => setAddOrEdit({ open: true, mode: "addUser", user: null })}
                    size="medium"
                    color="primary"
                    variant="contained"
                    label="Add User"
                  />
                </div>
              </div>
              <TableComponent
                columns={columns}
                data={rowsData}
                onSort={handleSort}
                onAction={handleAction}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                totalCount={manageUser?.payload?.count}
                tableHeadSmall
                rowsPerPageOptions={[10, 25, 50, 100]}
              />
              <RemoveUser
                openRemoveUser={openRemoveUser}
                setOpenRemoveUser={setOpenRemoveUser}
                editableUser={addOrEdit.user}
                handleCloseRemoveUser={handleCloseRemoveUser}
                setSearchEmployee={setSearchEmployee}
                customerId={customerId}
              />
            </div>
          ) : (
            <AddUser
              navigatedFor={addOrEdit.mode}
              paramId={addOrEdit.mode === "editUser" ? addOrEdit?.user?.userId : customerId}
              customerId={customerId}
              addorEditUser={addOrEdit}
              editableUser={addOrEdit.mode === "editUser" ? addOrEdit.user : null}
              managersList={manageUser?.ok ? manageUser.payload.list : []}
              setAddorEditUser={setAddOrEdit}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default UserList;
