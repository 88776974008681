import React from "react";
import AddIcon from "@mui/icons-material/Add";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";

import CustomButton from "components/common/Button";
import Avatar from "components/common/Avatar";

import "./NoEmployee.scss";

function NoEmployeeAdded({ setShowAssignEmployeeModal, showAddButton, showBorder }) {
  return (
    <div className={`mt-05 main-div ${showBorder ? "main-border" : ""}`}>
      <Avatar size="lg" backgroundColor="#F7F7F7">
        <PeopleOutlineOutlinedIcon htmlColor="#AFAFAF" />
      </Avatar>
      <p className="mt-05 heading-h5-semibold">No employees added yet!</p>
      {showAddButton && (
        <p className="para-body-s-regular no-employee-sub-heading">Start by adding new managed employees</p>
      )}
      {showAddButton && (
        <div className="mt-075">
          <CustomButton
            startIcon={<AddIcon />}
            label="Add Employee(s)"
            color="secondary"
            variant="outlined"
            size="small"
            onClick={() => setShowAssignEmployeeModal(true)}
          />
        </div>
      )}
    </div>
  );
}

export default NoEmployeeAdded;
