import validatePhoneFormat from "validations/PhoneNumberValidation";
import * as Yup from "yup";

export default Yup.object().shape({
  firstName: Yup.string()
    .min(2, "First Name must be at least 2 characters long")
    .max(50, "First Name cannot be longer than 50 characters")
    .required("First Name is required"),

  lastName: Yup.string()
    .min(2, "Last Name must be at least 2 characters long")
    .max(50, "Last Name cannot be longer than 50 characters")
    .required("Last Name is required"),

  country: Yup.string().required("Country is required"),

  nationalID: Yup.string().required("National ID is required"),

  gender: Yup.string().required("Gender is required"),

  datePicker: Yup.date().typeError("Invalid date format").required("Date of birth is required"),

  edgeEmail: Yup.string().email("Invalid email address").required("Edge email is required"),

  state: Yup.string().min(2, "State must be at least 2 characters long").required("State is required"),

  phoneNumber: Yup.string().test(validatePhoneFormat),

  city: Yup.string().min(2, "City must be at least 2 characters long").required("City is required"),

  zip: Yup.string().required("ZIP code is required"),

  address: Yup.string().min(5, "Address must be at least 5 characters long").required("Address is required"),

  hireDate: Yup.date().typeError("Invalid date format").required("Hire date is required"),

  jobTitle: Yup.string().min(2, "Job Title must be at least 2 characters long").required("Job title is required"),
});
